import React from 'react'
import { Gpt } from 'react-gpt-ads'

const BlogADBlock = ({ id }) => {
  const config = {
    find: true,
    id: 'div-gpt-ad-1723496896259-0',
    adUnit: '/22694030668/MedReps_Article',
    desktopSize: [300, 600],
    mobileSize: [300, 600],
  }

  return (
    <div
      id={id}
      className="border-y border-black py-12 mt-12 h-[600px] box-content flex justify-center items-center mx-[30px] lg:mx-0"
    >
      <Gpt
        adUnit={config.adUnit}
        name={`${config.id}-desktop`}
        size={config.desktopSize}
      />
    </div>
  )
}

export default BlogADBlock
