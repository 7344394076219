import React from 'react'

const BlogCard = ({ article, visibleDesc = true }) => {
  return (
    <div>
      <div
        className="w-full h-[184px] lg:h-[188px] bg-cover bg-no-repeat relative bg-[#edecec]"
        style={{
          backgroundImage: `url(${
            article?.imageUrl || article?.featured_image?.url
          })`,
        }}
      />
      <div className="career-advice-scroll w-full">
        <h5
          className={
            article?.isGated
              ? 'font-playfair text-2xl lg:text-3xl font-normal pt-3 mb-3 text-black gated-article'
              : 'font-playfair text-2xl lg:text-3xl	 font-normal pt-3 mb-3 text-black'
          }
        >
          {article?.title}
        </h5>
        {visibleDesc && (
          <p className="font-sans text-sm mb-2 text-black">
            {article?.description || ''}
          </p>
        )}
      </div>
    </div>
  )
}
export default BlogCard

export const BlogCardLoader = () => {
  return (
    <div
      className="w-full min-h-[400px] h-full bg-cover bg-no-repeat relative"
      style={{
        backgroundImage: `url(/loaders/blog-card-loader.png)`,
      }}
    ></div>
  )
}
