import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { strapiURL } from '../../../config'
import { Link } from 'gatsby'
import BlogCard, { BlogCardLoader } from '../common/blogCard'

const BlogLatest = () => {
  const [loading, setLoading] = useState(true)
  const [articles, setArticles] = useState([])

  useEffect(() => {
    loadArticles()
  }, [])

  const loadArticles = async () => {
    try {
      const filter = {
        page: 0,
      }
      const result = await axios.post(
        strapiURL + '/articles/searchIndex',
        filter
      )
      setArticles(result.data?.hits || [])
      setLoading(false)
    } catch (error) {
      console.log('error --- ', error)
    }
  }

  return (
    <div className="px-[30px] lg:px-0 mt-12">
      <div className="text-[15px] lg:text-xl	text-[#6D1E36] font-sans uppercase font-bold mb-6">
        Latest articles on MedReps
      </div>
      {!loading && (
        <div className="">
          {(articles || []).slice(0, 5).map(article => (
            <div className="border-b border-black mb-4">
              <Link
                to={`/medical-sales-careers/${article.slug}`}
                key={article.slug}
              >
                <BlogCard article={article} visibleDesc={false} />
              </Link>
            </div>
          ))}
        </div>
      )}
      {loading && (
        <div>
          <BlogCardLoader />
          <BlogCardLoader />
          <BlogCardLoader />
          <BlogCardLoader />
          <BlogCardLoader />
        </div>
      )}
    </div>
  )
}

export default BlogLatest
