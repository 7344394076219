import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { strapiURL } from '../../../config'
import { isArray } from 'lodash'
import { displayValueHandler } from '../../../functions'

const FeaturedJob = () => {
  const [featuredJobs, setFeaturedJobs] = useState([])
  useEffect(() => {
    loadFeaturedJobs()
  }, [])

  const renderJobLocation = job => {
    if (job.fullAddress) {
      let jobLocation
      if (isArray(job.fullAddress)) {
        jobLocation = job.fullAddress[0]
      } else {
        jobLocation = job.fullAddress
      }

      if (jobLocation) {
        if (job?.remote_enabled) {
          return (
            <div className="text-[12px] lg:text-[15px] text-location leading-0 ml-1">
              {jobLocation?.location?.formattedAddress} - Remote
            </div>
          )
        } else {
          return (
            <div className="text-[12px] lg:text-[15px] text-location leading-0 ml-1">
              {jobLocation?.location?.formattedAddress}
            </div>
          )
        }
      }
    } else {
      if (job?.remote_enabled) {
        return (
          <span className="text-[12px] lg:text-[15px] text-location leading-0 ml-1">
            Remote
          </span>
        )
      }
    }
    return null
  }

  const JobCard = ({ job }) => {
    if (job) {
      return (
        <div className={`listingCard p-2 relative border-b cursor-pointer`}>
          <a
            className="text-inherit block pointer-events-auto"
            href={`/job-detail/${job?.slug}`}
            target="_blank"
          >
            <div className="flex items-center justify-between w-full">
              <div>
                <p className="text-[15px] lg:text-xl font-semibold font-sans mb-0.5">
                  {job.title}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between w-full mt-2">
              <div className="flex items-center">
                <img src="/icons/icon-location-pin.svg" className="mb-0 mt-0" />
                <div>{renderJobLocation(job)}</div>
              </div>
              {
                <div
                  className={`flex items-center  ${
                    job.min_compensation || job.max_compensation
                      ? ''
                      : 'opacity-0'
                  }`}
                >
                  <img src="/icons/icon-currency.svg" className="mb-0 pr-0.5" />
                  <span className="text-[12px] lg:text-[15px] text-location">
                    ${job.min_compensation}K - ${job.max_compensation}K
                  </span>
                </div>
              }
            </div>
            <div className="flex items-end justify-between w-full">
              <div className="flex items-center mt-2">
                {job?.product_category ? (
                  <div className="bg-tag rounded flex py-1">
                    <span className="text-taText text-[8px] lg:text-[11px] mb-0 px-1">
                      {displayValueHandler(job?.product_category)}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
                {job?.primary_function ? (
                  <div className="bg-tag rounded flex py-1 ml-2">
                    <span className="text-tagText text-[8px] lg:text-[11px] mb-0 px-1">
                      {displayValueHandler(job?.primary_function)}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </a>
        </div>
      )
    } else {
      return null
    }
  }

  const loadFeaturedJobs = async () => {
    try {
      const result = await axios.post(
        strapiURL + '/jobs/searchIndex?featured=true&page=0',
        {}
      )

      console.log('featured articles ---', result.data?.hits)
      setFeaturedJobs(result.data?.hits || [])
    } catch (error) {}
  }

  return (
    <div className="px-[30px] lg:px-0 mt-[30px] lg:mt-0">
      <div className="text-xl	text-[#6D1E36] font-sans uppercase font-bold mb-6">
        Featured Jobs
      </div>
      <div className="border-[#DCE9EE] border">
        {featuredJobs.slice(0, 5).map(item => (
          <JobCard job={item} />
        ))}
      </div>
    </div>
  )
}

export default FeaturedJob
