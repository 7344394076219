import { Link } from 'gatsby'
import React, { useState, useMemo } from 'react'
import BlogCard, { BlogCardLoader } from '../common/blogCard'
import EarningCTA from '../earningCTA'
import NewsLetter from '../newsletter'
import { ARTICLE_CATEGORY_ALL } from '../../../data'

const BlogListingWithFilter = ({
  articles,
  industryArticles,
  loading,
  showArticleMore,
  activeCategory,
  industryArticlePageIndex,
  industryArticlePaginationInfo,
  handleViewMoreArticle,
  handleViewMoreIndustryArticle,
}) => {
  const [industryExpand, setIndustryExpand] = useState(false)

  const showIndustryArticleMore = useMemo(() => {
    if (
      industryArticlePaginationInfo.totalPage >
      industryArticlePageIndex + 1
    ) {
      return true
    }

    if (
      industryArticlePaginationInfo.totalPage === 1 &&
      industryArticlePaginationInfo.totalCount > 3
    ) {
      if (industryExpand) {
        return false
      } else {
        return true
      }
    }

    return false
  }, [industryArticlePageIndex, industryArticlePaginationInfo, industryExpand])

  const firstSection = articles?.slice(0, 9)
  const lastSection = articles?.slice(9, articles.length)

  return (
    <div className="">
      <div>
        {loading ? (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 container mx-auto mb-12 lg:mb-24 px-4">
            <BlogCardLoader />
            <BlogCardLoader />
            <BlogCardLoader />
            <BlogCardLoader />
            <BlogCardLoader />
            <BlogCardLoader />
          </div>
        ) : (
          <>
            {activeCategory === ARTICLE_CATEGORY_ALL &&
              industryArticles?.length && (
                <div>
                  <h4 className="font-playfair text-[29px] lg:text-[60px] text-black px-[30px] lg:px-0">
                    Industry News
                  </h4>
                  <div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 container mx-auto px-[30px] lg:px-0 pb-24">
                      {(industryExpand
                        ? industryArticles
                        : industryArticles.slice(0, 3)
                      ).map(article => {
                        return (
                          <Link
                            to={`/medical-sales-careers/${article.slug}`}
                            key={article.slug}
                          >
                            <BlogCard article={article} />
                          </Link>
                        )
                      })}
                    </div>
                    {showIndustryArticleMore && (
                      <div className="flex justify-center">
                        <button
                          className="text-white py-4 px-10 bg-[#C6004C] cursor-pointer font-sans font-bold rounded mb-12"
                          onClick={() => {
                            if (industryExpand) {
                              handleViewMoreIndustryArticle()
                            } else {
                              setIndustryExpand(true)
                            }
                          }}
                        >
                          View More
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            {activeCategory !== ARTICLE_CATEGORY_ALL && (
              <div>
                <h4 className="font-playfair text-[29px] lg:text-[60px] text-black px-[30px] lg:px-0">
                  {activeCategory}
                </h4>
              </div>
            )}
            {articles?.length ? (
              <div>
                {activeCategory === ARTICLE_CATEGORY_ALL && (
                  <h4 className="font-playfair text-[29px] lg:text-[60px] text-black px-[30px] lg:px-0">
                    Career Advice
                  </h4>
                )}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 container mx-auto px-[30px] lg:px-0">
                  {firstSection.map(article => {
                    return (
                      <Link
                        to={`/medical-sales-careers/${article.slug}`}
                        key={article.slug}
                      >
                        <BlogCard article={article} />
                      </Link>
                    )
                  })}
                </div>
                {activeCategory === ARTICLE_CATEGORY_ALL ? (
                  <div>
                    <NewsLetter />
                  </div>
                ) : null}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 container mx-auto px-[30px] lg:px-0">
                  {lastSection.map(article => {
                    return (
                      <Link
                        to={`/medical-sales-careers/${article.slug}`}
                        key={article.slug}
                      >
                        <BlogCard article={article} />
                      </Link>
                    )
                  })}
                </div>
                {showArticleMore ? (
                  <div className="flex justify-center">
                    <button
                      onClick={handleViewMoreArticle}
                      className="text-white py-4 px-10 bg-[#C6004C] cursor-pointer font-sans font-bold rounded mb-12 mt-24"
                    >
                      View More
                    </button>
                  </div>
                ) : null}
              </div>
            ) : (
              <h1 className="text-center text-merlot font-playfair w-full py-12">
                No search results
              </h1>
            )}
          </>
        )}
      </div>
      <EarningCTA />
    </div>
  )
}
export default BlogListingWithFilter
