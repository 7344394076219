import React from 'react'
import { Link } from 'gatsby'
import Slider from 'react-slick'

const BlogListingHero = ({ featuredArticles }) => {
  const settings = {
    className: 'slick-slider',
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  }

  return (
    <div className="bg-[#FEF8EA] py-0 md:py-12">
      <div className="container mx-auto">
        <Slider {...settings}>
          {(featuredArticles || []).map((article, articleIndex) => {
            return (
              <Link
                key={articleIndex}
                to={`/medical-sales-careers/${article.slug}`}
              >
                <div className="flex md:flex-row flex-col items-center py-16">
                  <div className="w-full md:w-1/2 px-[30px] py-2 relative flex justify-center z-20">
                    <img
                      className="h-[90px] absolute -top-8 z-10"
                      src="/images/vector-light-blue-2.png"
                      alt="vector"
                    />
                    {article?.imageUrl ? (
                      <>
                        <div
                          className="h-[18rem] lg:h-[32rem] w-full bg-cover bg-center bg-no-repeat"
                          style={{
                            backgroundImage: `url(${article?.imageUrl})`,
                          }}
                        ></div>
                      </>
                    ) : (
                      <div
                        className="h-[18rem] lg:h-[32rem] w-full bg-contain bg-no-repeat"
                        style={{
                          backgroundImage: `url(https://remotemore.com/images/macbook-right.png)`,
                        }}
                      ></div>
                    )}
                  </div>
                  <div className="w-full md:w-1/2 px-[30px] md:pl-12 mt-7 relative">
                    <div className="w-full flex justify-center">
                      <img
                        className="h-[140px] absolute -top-32 z-10"
                        src="/images/vector-light-red.png"
                        alt="vector"
                      />
                      <img
                        className="h-[120px] absolute right-0 z-10"
                        src="/images/vector-vertical-blue.png"
                        alt="vector"
                      />
                    </div>
                    <h1 className="font-playfair font-normal text-[26px] md:text-[31px] text-black">
                      {article.title}
                    </h1>
                    <div className="qoute-div">
                      <p className="font-sans text-[21px] font-normal text-black">
                        {article.description?.substring(0, 120).concat('...')}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}
export default BlogListingHero
