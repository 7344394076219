import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { strapiURL } from '../../../config'
import { SearchIcon } from '../../icons'

const BlogFilter = ({
  activeCategory,
  onChangeCategory,
  keyword,
  onChangeKeyword,
}) => {
  const [categories, setCategories] = useState([])
  const [show, setShow] = useState(true)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      function handleResize() {
        if (window.innerWidth > 640) {
          setIsMobile(false)
          setShow(true)
        } else {
          setIsMobile(true)
          setShow(false)
        }
      }
      window.addEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const getCategories = async () => {
      try {
        const { data } = await axios.get(strapiURL + '/article-categories')
        setCategories(data)
      } catch (e) {
        console.log(e)
      }
    }

    getCategories()
  }, [])

  const onSearch = e => {
    e.preventDefault()
    onChangeKeyword(e.target.keyword.value)
  }

  return (
    <div className="gap-y-0">
      <div>
        <div
          key={'all'}
          onClick={() => {
            onChangeCategory('All')
          }}
          className={`${
            activeCategory === 'All' || !show
              ? 'bg-button text-white'
              : 'text-black'
          } font-sans py-[8px] px-[30px] lg:py-[20px] lg:px-[40px] text-[18px] lg:text-xl font-semibold cursor-pointer ${
            show ? 'border-black' : ''
          }	border-b lg:border-[#D9D9D9] bg-[#FFF8F8] flex justify-between items-center`}
        >
          {show || activeCategory === 'All' ? 'All Articles' : activeCategory}
          <div
            className="block lg:hidden"
            onClick={evt => {
              evt.stopPropagation()
              setShow(!show)
            }}
          >
            {show ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>
        {show &&
          categories.map(category => {
            return (
              <div
                key={category.id}
                onClick={() => onChangeCategory(category.name)}
                className={`${
                  activeCategory === category.name
                    ? 'bg-button text-white'
                    : 'text-black'
                }  font-sans py-[8px] px-[30px] lg:py-[20px] lg:px-[40px] text-[18px] lg:text-xl font-semibold cursor-pointer	border-b border-black lg:border-[#D9D9D9] bg-[#FFF8F8]`}
              >
                {category.name}
              </div>
            )
          })}
      </div>
      <div className="flex flex-col justify-start mt-6 mb-12 lg:my-12 px-[30px] lg:px-0">
        <form onSubmit={onSearch}>
          <div className="border border-border rounded-lg flex items-center">
            <div className="text-[#926874] absolute ml-5">
              <SearchIcon />
            </div>
            <input
              id="keyword"
              type="text"
              placeholder="Search"
              className="w-full focus:outline-none rounded-lg py-5 font-sans pl-14 placeholder-[#232323]"
              defaultValue={keyword}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default BlogFilter
