import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import { strapiURL } from '../../../config'
import BlogCard, { BlogCardLoader } from '../common/blogCard'
import { Link } from 'gatsby'

const BlogPopular = () => {
  const [featuredArticles, setFeaturedArticles] = useState([])
  const [featuredArticlePaginationInfo, setFeaturedArticlePaginationInfo] =
    useState({
      totalCount: 0,
      totalPage: 0,
      perPageCount: 0,
    })
  const [featuredArticlePageIndex, setFeaturedArticlePageIndex] = useState(0)

  useEffect(() => {
    loadFeaturedArticles(featuredArticlePageIndex)
  }, [featuredArticlePageIndex])

  const loadFeaturedArticles = async (page = 0) => {
    try {
      const result = await axios.post(strapiURL + '/articles/searchIndex', {
        featured: true,
        page,
      })

      if (page > 0) {
        const updatedArticles = featuredArticles.concat(result.data?.hits || [])
        setFeaturedArticles(updatedArticles)
      } else {
        setFeaturedArticles(result.data?.hits || [])
      }

      setFeaturedArticlePaginationInfo({
        totalCount: result.data?.nbHits,
        totalPage: result.data?.nbPages,
        perPageCount: result.data?.hitsPerPage,
      })
    } catch (error) {
      console.log('error --- ', error)
    }
  }

  const handleViewMoreFeaturedArticle = () => {
    setFeaturedArticlePageIndex(prev => prev + 1)
  }

  const showFeaturedArticleMore = useMemo(
    () =>
      featuredArticlePaginationInfo.totalPage > featuredArticlePageIndex + 1,
    [featuredArticlePageIndex, featuredArticlePaginationInfo]
  )

  return (
    <div className="mt-[30px]">
      <h1 className="text-[15px] lg:text-2xl container mx-auto pb-6 font-sans text-[#6D1E36] px-[30px] lg:px-0 uppercase font-bold	">
        Most Popular
      </h1>
      {featuredArticles?.length ? (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-10 container mx-auto px-[30px] lg:px-0 pb-24">
            {featuredArticles?.slice(0, 4).map(article => {
              return (
                <Link
                  to={`/medical-sales-careers/${article.slug}`}
                  key={article.slug}
                >
                  <BlogCard article={article} />
                </Link>
              )
            })}
          </div>
          {/* {showFeaturedArticleMore && (
            <div className="flex justify-center">
              <button
                className="text-white py-4 px-10 bg-[#C6004C] cursor-pointer font-sans font-bold rounded mb-12"
                onClick={handleViewMoreFeaturedArticle}
              >
                View More
              </button>
            </div>
          )} */}
        </>
      ) : (
        <div className="text-center pb-12">
          <h1>No Featured Blog</h1>
        </div>
      )}
    </div>
  )
}

export default BlogPopular
