import React from 'react'
import cookies from 'react-cookies'
import { Link } from 'gatsby'
const EarningCTA = () => {
  let user = cookies.load('user')
  return (
    <>
      {!user ? (
        <div className="px-[30px] py-10 lg:py-32 bg-themePink w-full">
          <div className="container mx-auto flex flex-col justify-center items-center">
            <h1 className="font-playfair text-[26px] lg:text-4xl font-normal text-center mb-2">
              How much do medical sales reps earn?
            </h1>
            <p className="font-normal font-sans text-base text-center">
              Join MedReps to get detailed salary and commission information
            </p>
            <div className="w-6/12 flex items-center mx-auto justify-center">
              <Link to="/join-now">
                <button className="text-white py-4 px-8 bg-[#A51440] cursor-pointer font-sans font-bold rounded">
                  Join Now!
                </button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </>
  )
}
export default EarningCTA
